@import "normalize";
@import "colors";
html,body{
    height: 100%;
}
body{
    background: #fff url('../img/bg.jpg') no-repeat center;
    font-family: 'Rubik', sans-serif;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: $text_color;
}
h1{
    color: $main_color;
    text-transform: uppercase;
    font-size: 36px;
    text-align: center;
    
}
img.logo{
    width: 200px;
    height: auto;
    max-width: 40%;
}
.coordonnees{
    p{
        text-align: center;
        padding-bottom: 15px;
        margin-bottom: 0px;
        margin-top: 15px;
        border-bottom: 1px solid $borders;
        &:last-child{border: none;}
        a{
            color: $text_color;
            text-decoration: none;
            transition: .5s ease;

            &:hover{
                color: $main_color;
            }
        }
        i{
            color: $main-color;
            font-size: 18px;
            margin-right: 5px;
        }
    }
}
footer{
    background-color: $second_color;
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;

    .imgbox{
        height: 90px;
        width: 90px;
        background-color: #fff;
        border-radius: 100%;
        margin-right: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 70%;
            width: 70%;
            object-fit: contain;
            
        }
    }
    p{
        font-size: 18px;
    }
}

@media screen and(max-width:1199px){
    h1{
        font-size: 30px;
    }
}
@media screen and(max-width:767px){
    body{
        padding-bottom: 140px;
    }
    h1{
        font-size: 25px;
    }
    .coordonnees{
        p{
            font-size: 20px;
        }
    }
}
@media screen and(max-width:320px){

    body{
        padding-bottom: 0;
        display: block;
        text-align: center;
        img.logo{
            padding-top: 25px;
            margin: 0 auto;
            display: block;
        }
    }
    h1{
        font-size: 20px;
    }
    .coordonnees{
        flex-grow: 1;
        p{
            font-size: 18px;
            i{
                font-size: 16px;
            }
        }
    }
    footer{
        padding: 15px 0;
        margin-top: 50px;
        position: static;
        [data-aos-delay] {
            transition-delay: 0 !important;
          }
        .imgbox{
            height: 60px;
            width: 60px;
        }
        p{
            font-size: 16px;
        }
    }
}